<template>
  <wound-healing-template
    :prolonged-healing-time="prolongedHealingTime"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import WoundHealingTemplate from '@/modules/questionnaire/components/steps/common/medical-background/wound-healing/WoundHealingTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import disableScrollOnThisPage from '@/modules/questionnaire/mixins/disableScrollOnThisPage';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('prolongedHealingTime')];

export default {
  name: 'WoundHealing',
  components: {
    WoundHealingTemplate
  },
  mixins: [makeStep(FIELDS), disableScrollOnThisPage],
  watch: {
    prolongedHealingTime() {
      this.showNextStep();
    }
  }
};
</script>
